<script lang="ts" setup>
// #region Globals
const route = useRoute();
// #endregion

// #region Props
const props = defineProps({
   personTodo: { type: Object as PropType<PersonTodo>, required: true },
});
// #endregion

// #region Person Todo Modal
const personTodoModal = computed(() => ({
   ...route,
   hash: `${GlobalModalKey.PersonTodo}?id=${props.personTodo.sync_id}`,
}));
// #endregion

// #region Tenant
const creator = computed(() => ({
   ...props.personTodo?.creator_user,
   full_name: `${props.personTodo?.creator_user?.first_name} ${props.personTodo?.creator_user?.last_name}`,
}));
const tenant = computed(() => creator.value?.tenant);
// #endregion
</script>

<template>
   <NuxtLink :to="personTodoModal" class="person-todo-card">
      <small class="person-todo-card__title"> {{ $t('profile.person-todo-title') }}: {{ personTodo.chapter.name }} </small>

      <div v-if="creator" class="person-todo-card__creator">
         <LayoutCustomerLogos v-if="tenant" :logos="[{ logo: '', name: creator.full_name || '' }]" />
         <span v-if="tenant">{{ creator.first_name }} | {{ tenant?.name }} </span>
      </div>
   </NuxtLink>
</template>

<style lang="scss" scoped>
.person-todo-card {
   width: 100%;
   height: fit-content;
   min-height: toRem(80);

   background-color: var(--nxt-card-background);
   color: var(--nxt-dark);
   border-radius: var(--nxt-radius);
   padding: var(--nxt-gutter-small);

   display: grid;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto 1fr;
   grid-template-areas:
      'title icon'
      'creator icon';
   gap: var(--nxt-gutter-small);

   text-decoration: none;

   &:visited,
   &:active,
   &:hover {
      text-decoration: none;
   }

   &__title {
      grid-area: title;
   }

   &__icon {
      grid-area: icon;
      align-self: center;
      padding: var(--nxt-gutter-large);
   }

   &__creator {
      grid-area: creator;
   }
}

.person-todo-card__creator {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
}
</style>
